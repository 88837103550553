// @ts-check
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// core
import {
  AnnouncementAlert,
  DataTable,
  OPEN_MODE,
  resolveRouteWithId,
  routerUrl,
} from 'core';
import { recentCampaignTableConfig } from 'core/components/Tables/configs';

// state
import { useAppDispatch, useAppSelector } from 'core/store';
import {
  closeAnnouncment,
  getActiveAnnouncementsAsync,
  getCampaignListAsync,
  resetAlertMessages,
  resetCampaign,
  resetCampaignPreview,
  resetPageSummary,
  selectMostRecentOpenAnnouncement,
  setCampaignData,
  setSelectedRoute,
} from 'core/store/slices';

const WelcomeContainer = styled.div`
  span.welcome-warning {
    background: #ffc627;
  }
`;

const Home = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { campaigns, loading: campaignListLoading } = useAppSelector(
    (state) => state.campaignList,
  );
  const mostRecentOpenAnnouncement = useAppSelector(
    selectMostRecentOpenAnnouncement,
  );

  const [announcementOpen, setAnnouncementOpen] = useState(false);

  useEffect(() => {
    dispatch(setSelectedRoute(routerUrl.HOME));
    dispatch(
      getCampaignListAsync({
        searchFilter: {
          perPage: 10,
          all: false,
        },
      }),
    );
    // @ts-ignore
    dispatch(getActiveAnnouncementsAsync());
  }, [dispatch]);

  useEffect(() => {
    setAnnouncementOpen(!!mostRecentOpenAnnouncement);
  }, [mostRecentOpenAnnouncement]);

  return (
    <div>
      {announcementOpen && mostRecentOpenAnnouncement ? (
        <AnnouncementAlert
          content={mostRecentOpenAnnouncement.content}
          title={mostRecentOpenAnnouncement.title}
          isOpen={announcementOpen}
          onClose={() => {
            setAnnouncementOpen(false);
            dispatch(closeAnnouncment(mostRecentOpenAnnouncement));
          }}
        />
      ) : null}
      <WelcomeContainer>
        <section>
          <h2 data-testid="home">Welcome to Campaign Builder</h2>
          <div>
            <p>
              Whether you are new to creating outreach campaigns or a seasoned
              pro, we developed Campaign Builder to help make it easier for you
              to communicate with and reach out to students. The application can
              help you create finely segmented lists of students. In the future,
              it will also assist you with getting those lists into ASU & 3rd
              party applications (for example, Salesforce, Mobile App, Chatbot).
              We are also collecting metadata through this application about the
              campaigns you run and why. Those data may be useful in evaluating
              the efficacy and impact of your work.
            </p>
            <p>
              <span className="highlight-gold welcome-warning">
                <strong>An important note:</strong>
              </span>{' '}
              Because Campaign Builder can create files that contain regulated
              information (for example, students with a FERPA hold), data
              generated by Campaign Builder should only be used for university
              business; Campaign Builder data should never be released to a
              third party.
            </p>
            <p>
              If you have any questions about the data or the application more
              broadly, please contact the Campaign Builder team at{' '}
              <a href="mailto:aacb@asu.edu">aacb@asu.edu</a> or via Slack
              #aacb_support.
            </p>
          </div>
          <section className="mt-4 mb-4">
            <button
              type="button"
              className="btn btn-md btn-gold"
              onClick={() => {
                dispatch(resetAlertMessages());
                dispatch(resetPageSummary());
                dispatch(resetCampaign());
                dispatch(resetCampaignPreview());
                dispatch(setCampaignData({ openMode: OPEN_MODE.CREATE }));
                dispatch(setSelectedRoute(routerUrl.CAMPAIGN_CREATE_DATA));
                history.push(routerUrl.CAMPAIGN_CREATE_DATA);
              }}
            >
              Create a new Campaign
            </button>
          </section>
        </section>
      </WelcomeContainer>
      <section>
        <DataTable
          testId="tbl-recent-campaigns"
          loading={campaignListLoading}
          config={recentCampaignTableConfig}
          rows={campaigns}
          caption="My recent campaigns"
          actionButtonText="View all campaigns"
          noDataPlaceholder="Nothing to show yet. 
            Here’s your chance to create something."
          showActionButton
          onCellClick={({ id }) => {
            const url = resolveRouteWithId(routerUrl.CAMPAIGN_DETAILS, id);
            history.push(url);
          }}
          onActionButtonClick={() => {
            history.push(routerUrl.CAMPAIGN_SEARCH);
          }}
        />
      </section>
    </div>
  );
};

export { Home };
