// @ts-check
import React from 'react';
import { ErrorValidation, RequiredIcon, Tooltip } from 'core/components';

/**
 * @param {{
 * announcementContent: string
 * onChange: (e) => void
 * readonly: boolean
 * errors?: string []
 * }} props
 */
function AnnouncementContent({
  announcementContent,
  onChange,
  readonly,
  errors,
}) {
  return (
    <div className="form-group" id="announcement-content-field">
      <label>
        <RequiredIcon />
        Announcement Content
      </label>
      <Tooltip tooltipId="announcement-content-tooltip">
        This is a content of the announcement; minimum 5 characters.
      </Tooltip>
      <textarea
        data-testid="announcement-content"
        className={`form-control ${errors.length > 0 ? 'is-invalid' : ''}`}
        rows={3}
        placeholder="Announcement content"
        name="content"
        id="content"
        value={announcementContent}
        required
        readOnly={readonly}
        disabled={readonly}
        onChange={onChange}
      />
      <ErrorValidation errors={errors} />
    </div>
  );
}

export { AnnouncementContent };
